import React from "react";
import "../../styles/find-car-form.css";
import "../../styles/find-car-form.css";
import { Form } from "reactstrap";

const FindCarForm = () => {
  return <Form className="form"></Form>;
};

export default FindCarForm;
